<template>
<footer>
    <div class="footer-top bg-primary-900 text-white p-5">
        <div class="container">
            <div class="row">
                <div class="col-4 first">
                    <img src="@/assets/logo/footer.png" alt="" />

                    <span> تقوم بتسهيل عملية بيع الأصول الثابتة والمنقولة </span>

                </div>
                <div class="col-4 second flex flex-col items-center justify-center">
                    <div class="text-xl font-bold">للتواصل عبر</div>
                    <div class="mt-3">
                        <unicon name="youtube" fill="#e35c65" width="30" height="30" class="ml-2"></unicon>
                        <unicon name="twitter" fill="#e35c65" width="30" height="30" class="ml-2"></unicon>
                        <unicon name="whatsapp" fill="#e35c65" width="30" height="30" class="ml-2"></unicon>
                    </div>
                </div>
                <div class="col-4 third flex items-center justify-end">
                    <div>
                        <div class="font-bold">للدعم الفني</div>

                        <div class="font-bold">info@tasfia.sa</div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="footer-bottom text-primary-900 flex items-center justify-between">
            <div class="right">
                <router-link to="/terms" class="text-primary-900">
                    شروط الإستخدام وإخلاء المسؤولية
                </router-link>
                |
                <router-link to="/privacy" class="text-primary-900">
                    سياسة الخصوصية وسرية المعلومات
                </router-link>
            </div>
            <div class="left">
                <div class="text">جميع الحقوق محفوظة لشركة تصفية</div>
            </div>
        </div>
    </div>
</footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
footer {
    background: #ccc;

    border-top: 1px solid #ccc;

    .footer-top {
        .first {
            span {
                display: block;

            }

            img {
                margin-bottom: 10px;
                width: 180px;

            }
        }
    }

    .footer-bottom {
        padding: 5px 15px;

        .left {
            display: flex;
            align-items: center;

            img {
                width: 140px;
                margin-right: 15px;
            }
        }
    }
}
</style>
