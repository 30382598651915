<template>
  <section class="mail-list">
    <div class="container">
      <div class="list-con">
        <div class="inputs">
          <div class="row">
            <div class="col-2">
                <div class="text">اشترك في نشرتنا الإخبارية</div>
            </div>
            <div class="col-3">
              <FormulateInput
                type="text"
                name="name"
                placeholder="الإسم"
                validation-name="الإسم"
                validation="required"
                class="input"
              />
            </div>
            <div class="col-3">
              <FormulateInput
                type="email"
                name="email"
                placeholder="البريد الإلكتروني"
                validation-name="البريد الإلكتروني"
                validation="required"
                class="input"
              />
            </div>
            <div class="col-3">
              <FormulateInput
                type="tel"
                name="phone"
                placeholder="رقم الهاتف"
                validation-name="رقم الهاتف"
                validation="required"
                class="input"
              />
            </div>
            <div class="col-1">
            <button type="submit">الإشتراك</button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.mail-list {
    background: #E9ECEF;

}
.list-con {
  display: flex;
  padding: 20px 0px;
  .text {
    font-size: 14px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .inputs {
    width: 100%;
    .col-3 {
      padding: 5px;
    }
    .input {
      width: 100%;
      background-color: white;
      
    }
    button {
        height: 44px;
        width: 100%;
        border: none;
        color: white;
        background-color: $primary;
        text-align: center;
        border-radius: 4px;
    }
  }
}
</style>
