<template>
<section class="cards-component">
    <sweet-modal ref="shareModal" class="share-modal">
        <div class="flex flex-col items-center justify-center">
            <button class="bg-black text-white rounded-lg py-2 px-3 border-none outline-none mb-3 flex items-center" @click="shareTwitter()">
                <span>مشاركة على X</span>
                <img src="@/assets/icons/twitter-x.svg" class="mr-2 w-8" />
            </button>
            <button class="bg-green-700 text-white rounded-lg py-2 px-3 border-none outline-none flex items-center" @click="shareWhatsapp()">
                <span>مشاركة على واتساب</span>

                <svg class="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.50002 12C3.50002 7.30558 7.3056 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C10.3278 20.5 8.77127 20.0182 7.45798 19.1861C7.21357 19.0313 6.91408 18.9899 6.63684 19.0726L3.75769 19.9319L4.84173 17.3953C4.96986 17.0955 4.94379 16.7521 4.77187 16.4751C3.9657 15.176 3.50002 13.6439 3.50002 12ZM12 1.5C6.20103 1.5 1.50002 6.20101 1.50002 12C1.50002 13.8381 1.97316 15.5683 2.80465 17.0727L1.08047 21.107C0.928048 21.4637 0.99561 21.8763 1.25382 22.1657C1.51203 22.4552 1.91432 22.5692 2.28599 22.4582L6.78541 21.1155C8.32245 21.9965 10.1037 22.5 12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5ZM14.2925 14.1824L12.9783 15.1081C12.3628 14.7575 11.6823 14.2681 10.9997 13.5855C10.2901 12.8759 9.76402 12.1433 9.37612 11.4713L10.2113 10.7624C10.5697 10.4582 10.6678 9.94533 10.447 9.53028L9.38284 7.53028C9.23954 7.26097 8.98116 7.0718 8.68115 7.01654C8.38113 6.96129 8.07231 7.046 7.84247 7.24659L7.52696 7.52195C6.76823 8.18414 6.3195 9.2723 6.69141 10.3741C7.07698 11.5163 7.89983 13.314 9.58552 14.9997C11.3991 16.8133 13.2413 17.5275 14.3186 17.8049C15.1866 18.0283 16.008 17.7288 16.5868 17.2572L17.1783 16.7752C17.4313 16.5691 17.5678 16.2524 17.544 15.9269C17.5201 15.6014 17.3389 15.308 17.0585 15.1409L15.3802 14.1409C15.0412 13.939 14.6152 13.9552 14.2925 14.1824Z" fill="#ffffff"></path>
                    </g>
                </svg>
            </button>
        </div>
    </sweet-modal>
    <sweet-modal ref="loginModal" class="login-modal">
        <div class="flex items-center justify-center">
            الرجاء
            <router-link to="/login" class="underline mx-1">
                تسجيل الدخول
            </router-link>
            للمتابعة
        </div>
    </sweet-modal>

    <div class="container my-4">
        <div class="bg-gray-100 rounded-xl shadow-lg my-4 py-3 px-4">
            <div class="grid xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-1 xs:grid-cols-1 grid-cols-1 gap-2">
                <div class="block w-full mx-1 text-white h-16 cursor-pointer" v-for="type in lists.auction_types" :key="type.id" @click="filterByCat(type.id)">
                    <div class="bg-primary-900 w-full h-full flex items-center justify-center rounded" :class="{ 'active-filter': aciveFilterCat == type.id }">
                        <img :src="type.active_icon" class="w-14 ml-2" />
                        <span class="text"> {{ type.name }} </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="search-bar">
            <div class="right">
                <div class="input-con">
                    <FormulateInput class="form-input" name="المنطقة" label="المنطقة" @change="selectRegion($event.target.value)" :options="formatOptions(lists.regions)" type="select" />
                    <div class="img-con">
                        <img src="@/assets/icons/search.png" alt="" />
                    </div>
                </div>
                <div class="input-con">
                    <FormulateInput class="form-input" name="المدينة" label="المدينة" @change="selectCity($event.target.value)" :options="formatOptions(lists.cities)" type="select" />
                    <div class="img-con">
                        <img src="@/assets/icons/search.png" alt="" />
                    </div>
                </div>
                <!-- <div class="bell-con">
                    <img src="@/assets/icons/bell.png" alt="" />
                </div> -->
            </div>
            <div class="left">
                <ul>
                    <li @click="showAll()" :class="{ active: activeFilterStatus == 0 }">
                        <router-link to="/">الكل {{ info.total }} </router-link>
                    </li>
                    <li @click="filterByStuts(2)" :class="{ active: activeFilterStatus == 2 }">
                        <router-link to="/">جاري {{ info.running }}</router-link>
                    </li>
                    <li @click="filterByStuts(1)" :class="{ active: activeFilterStatus == 1 }">
                        <router-link to="/">قادم {{ info.comming }} </router-link>
                    </li>

                    <li @click="filterByStuts(3)" :class="{ active: activeFilterStatus == 3 }">
                        <router-link to="/" v-if="info.finshed >= 100">منتهي +99 </router-link>
                        <router-link to="/" v-else>منتهي {{ info.finshed }} </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="cards-loading" v-if="!lists.auctions.length > 0 && loading">
            <div class="row">
                <div class="card-con col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12" v-for="n in 9" :key="n">
                    <vue-skeleton-loader type="rect" width="100%" height="300px" animation="fade" class="loading-element" rounded />
                </div>
            </div>
        </div>

        <div class="cards" v-if="lists.auctions">
            <div class="flex flex-col items-center justify-center" v-if="lists.auctions.length == 0 && !loading">
                <div class="noauctions">
                    <img src="@/assets/icons/login-ill.png" alt="" />
                </div>
                <div class="text-2xl text-gray-600 font-bold py-3">
                    لا يوجد مزادات
                </div>
            </div>
            <div class="row" v-if="lists.auctions.length > 0">
                <div class="card-con col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12" v-for="(item, index) in lists.auctions" :key="index">
                    <div class="card">
                        <router-link :to="getRoute(item.id)">
                            <div class="card-top-over-con" v-if="item.statusByDate == 3 || item.status == 3">
                                <div class="card-top-over"></div>
                            </div>

                            <div class="finished" v-if="item.statusByDate == 3"></div>

                            <div class="stopped" v-if="item.status == 3"></div>

                            <!-- <div class="card-heading">
                  {{ item.title }}
                </div> -->

                            <div class="card-img relative">
                                <img :src="item.card_img" alt="" />
                                <div class="finished-text" v-if="item.statusByDate == 3">
                                    منتهي
                                </div>

                                <div class="stopped-text" v-if="item.status == 3">
                                    <div>
                                        <unicon class="unicon" name="ban" fill="#484080" width="90" height="90"></unicon>
                                    </div>

                                    <div class="text-ar">المزاد موقوف</div>
                                    <div class="text-en">Auction Stopped</div>
                                </div>
                                <!-- <div
                    class="card-type absolute right-0 top-6 bg-white rounded-e-full text-primary-600 px-3 py-1"
                  >
                    مزاد {{ item.typeBy }}
                  </div> -->
                                <div class="card-top-con">
                                    <div class="card-top flex justify-between text-white" :class="item.statusByDate == 2 ? runing : comeing">
                                        <div class="right">{{ item.title }}</div>
                                        <div class="left" v-if="item.city != null">
                                            <div class="icon">
                                                <img src="@/assets/icons/location.png" alt="" />
                                            </div>
                                            <div class="text">{{ item.city.name }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="time-left bg-primary-900 text-white" v-if="item.statusByDate == 1">
                                    قادم
                                </div>
                                <div class="time-left bg-secondary-500 text-white" v-if="item.statusByDate == 2">
                                    جاري
                                </div>
                            </div>
                            <div class="card-text">
                                <div :class="item.statusByDate == 2 ? runing : comeing">
                                    <div></div>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="name-status">
                                                <div class="">مزاد {{ item.typeBy }}</div>
                                                <!-- <div class="heading-locati on" v-if="item.city != null">
                                                    <div class="icon">
                                                        <img src="@/assets/icons/location.png" alt="" />
                                                    </div>
                                                    <div class="text">{{ item.city.name }}</div>
                                                </div> -->
                                                <!-- <div class="name">
                            {{ item.typeBy }}

                          </div>
                          <div class="status" >
                            {{ getStatus(item.statusByDate) }}

                          </div> -->
                                            </div>
                                        </div>
                                        <!-- <div class="col-2 flex items-center">
                                            <div class="icon">
                                                <img src="@/assets/icons/location.png" alt="" class="w-3" />
                                            </div>
                                            <div class="text-sm ps-1">{{ item.city.name }}</div>
                                        </div> -->
                                        <div class="col-8">
                                            <div class="mz_cards_side_time_left">
                                                <div class="row">
                                                   {{ handleCount(item) }}
                                                    <!-- <div class="col-lg-3 col-md-12 col-sm-12 col-12">
                                        <div class="mz_time_left_text">يبدأ بعد</div>
                                      </div> -->

                                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <div class="mz_time_left_items">
                                                            <div class="mz_time_left_item mz_time_left_second">
                                                                <div class="mz_time_left_item_value" :id="`${item.created_at}-${item.id}-seconds`">
                                                                    00
                                                                </div>
                                                                <div class="mz_time_left_item_dots">:</div>
                                                                <div class="mz_time_left_item_text">
                                                                    ثانية
                                                                </div>
                                                            </div>
                                                            <div class="time_dots">:</div>
                                                            <div class="mz_time_left_item mz_time_left_minute">
                                                                <div class="mz_time_left_item_value" :id="`${item.created_at}-${item.id}-minutes`">
                                                                    00
                                                                </div>
                                                                <div class="mz_time_left_item_dots">:</div>
                                                                <div class="mz_time_left_item_text">
                                                                    دقيقة
                                                                </div>
                                                            </div>
                                                            <div class="time_dots">:</div>

                                                            <div class="mz_time_left_item mz_time_left_hour">
                                                                <div class="mz_time_left_item_value" :id="`${item.created_at}-${item.id}-hours`">
                                                                    00
                                                                </div>
                                                                <div class="mz_time_left_item_dots">:</div>
                                                                <div class="mz_time_left_item_text">ساعة</div>
                                                            </div>
                                                            <div class="time_dots">:</div>

                                                            <div class="mz_time_left_item mz_time_left_day">
                                                                <div class="mz_time_left_item_value day_value" :id="`${item.created_at}-${item.id}-days`">
                                                                    00
                                                                </div>
                                                                <div class="mz_time_left_item_text">يوم</div>
                                                                <!-- <div class="mz_time_left_item_dots">
                                                      :
                                                  </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-5">
                                            <div class="b-b flex items-center p-1">
                                                <div class="icon">
                                                    <unicon class="unicon" name="box" fill="#484080" width="25" height="25"></unicon>
                                                </div>
                                                <div class="ms-1">
                                                    عدد المنتجات {{ item.productCount }}
                                                </div>
                                            </div>
                                            <div class="flex items-center p-1">
                                                <div class="icon">
                                                    <unicon class="unicon" name="clock" fill="#484080" width="25" height="25"></unicon>
                                                </div>
                                                <div class="ms-1 p-1">
                                                    يبدأ
                                                    <span> {{ item.dayName }} </span>
                                                    {{ getDateTime(item.start_at) }}
                                                    <!-- <br />
                              الى
                              <span> {{ item.endDayName }} </span>
                              {{ getDateTime(item.end_at) }} -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-7 border-r border-white w-full p-1">
                                            <div class="flex items-center p-1 h-full">
                                                <div class="icon">
                                                    <unicon class="unicon" name="calender" fill="#484080" width="25" height="25"></unicon>
                                                </div>
                                                <div class="flex flex-col w-full p-1 justify-between h-full">
                                                    <div class="flex justify-evenly">
                                                        <div>
                                                            أيام المزاد
                                                            <span>{{
                                  getDiffInDays(item.start_at, item.end_at)
                                }}</span>
                                                            أيام
                                                        </div>
                                                    </div>

                                                    <div class="flex justify-evenly">
                                                        <!-- <div>الفترة</div> -->
                                                        <div>{{ formatDate(item.start_at) }}</div>
                                                        <div>
                                                            {{ formatDate(item.end_at) }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <div class="card-footer border-t border-white">
                            <div class="right">
                                <img src="@/assets/icons/heart-2.png" alt="" @click="loveCard()" />
                                <v-popover offset="8">
                                    <!-- This will be the popover target (for the events and position) -->
                                    <button class="tooltip-target b3">
                                        <img src="@/assets/icons/share-2.png" alt="" />
                                    </button>

                                    <!-- This will be the content of the popover -->
                                    <template slot="popover">
                                        <div class="bg-gray-300 flex items-center px-2 py-1 border border-gray shadow rounded-md">
                                            <button class="social-btn bg-transparent text-white rounded-lg py-2 px-1 border-none outline-none flex items-center" @click="shareTwitter()">
                                                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 462.799">
                                                    <path fill-rule="nonzero" d="M403.229 0h78.506L310.219 196.04 512 462.799H354.002L230.261 301.007 88.669 462.799h-78.56l183.455-209.683L0 0h161.999l111.856 147.88L403.229 0zm-27.556 415.805h43.505L138.363 44.527h-46.68l283.99 371.278z" />
                                                </svg>
                                            </button>
                                            <button class="social-btn bg-transparent text-white py-2 px-1 border-none outline-none flex items-center" @click="shareWhatsapp()">
                                                <svg class="" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                    <g id="SVGRepo_iconCarrier">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.50002 12C3.50002 7.30558 7.3056 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C10.3278 20.5 8.77127 20.0182 7.45798 19.1861C7.21357 19.0313 6.91408 18.9899 6.63684 19.0726L3.75769 19.9319L4.84173 17.3953C4.96986 17.0955 4.94379 16.7521 4.77187 16.4751C3.9657 15.176 3.50002 13.6439 3.50002 12ZM12 1.5C6.20103 1.5 1.50002 6.20101 1.50002 12C1.50002 13.8381 1.97316 15.5683 2.80465 17.0727L1.08047 21.107C0.928048 21.4637 0.99561 21.8763 1.25382 22.1657C1.51203 22.4552 1.91432 22.5692 2.28599 22.4582L6.78541 21.1155C8.32245 21.9965 10.1037 22.5 12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5ZM14.2925 14.1824L12.9783 15.1081C12.3628 14.7575 11.6823 14.2681 10.9997 13.5855C10.2901 12.8759 9.76402 12.1433 9.37612 11.4713L10.2113 10.7624C10.5697 10.4582 10.6678 9.94533 10.447 9.53028L9.38284 7.53028C9.23954 7.26097 8.98116 7.0718 8.68115 7.01654C8.38113 6.96129 8.07231 7.046 7.84247 7.24659L7.52696 7.52195C6.76823 8.18414 6.3195 9.2723 6.69141 10.3741C7.07698 11.5163 7.89983 13.314 9.58552 14.9997C11.3991 16.8133 13.2413 17.5275 14.3186 17.8049C15.1866 18.0283 16.008 17.7288 16.5868 17.2572L17.1783 16.7752C17.4313 16.5691 17.5678 16.2524 17.544 15.9269C17.5201 15.6014 17.3389 15.308 17.0585 15.1409L15.3802 14.1409C15.0412 13.939 14.6152 13.9552 14.2925 14.1824Z"></path>
                                                    </g>
                                                </svg>
                                            </button>
                                        </div>
                                    </template>
                                </v-popover>
                            </div>

                            <div class="left">
                                <div class="card-footer-imgs" v-if="item.care_of != null">
                                    <img src="@/assets/brands/Infath.png" alt="" width="40" height="40" />
                                    <!-- <img src="@/assets/brands/Infath.png" alt="" width="40" height="40"> -->
                                    <img @click="getProfileRoute(item.user.id)" :src="item.user.logo" alt="" width="40" height="40" />
                                </div>

                                <div @click="getProfileRoute(item.user.id)" class="card-footer-imgs" v-else>
                                    <!-- <p>{{ item.user.company_name }}</p> -->

                                    <img :src="item.user.logo" alt="" width="40" height="40" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination-con" v-if="lists.auctions.length > 0">
            <div class="pagination">
                <div class="pagination-arrow">
                    <img @click="setAuctions(paginationData.currentPage - 1)" src="@/assets/icons/arrow.png" alt="" />
                </div>
                <div class="pagination-btns">
                    <button v-for="p in paginationData.lastPage" :key="p" :class="{ active: p == paginationData.currentPage }" @click="setAuctions(p)">
                        {{ p }}
                    </button>
                </div>
                <div class="pagination-arrow arrow-left">
                    <img @click="setAuctions(paginationData.currentPage + 1)" src="@/assets/icons/arrow.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
export default {
    components: {
        SweetModal,
        SweetModalTab,
    },
    data() {
        return {
            comeing: "card-text-heading bg-primary-900 text-white",
            runing: "card-text-heading bg-secondary-500 text-white",
            startCountDown: false,
            timer: "",
            seconds: 0,
            minutes: 0,
            hours: 0,
            days: 0,
            intervals: [],
            addedIntervals: [],
            activeFilterStatus: 0,
            aciveFilterCat: 1,
        };
    },
    computed: {
        ...mapGetters("Auth", ["auth_user"]),
        ...mapGetters("Auctions", ["info", "lists", "paginationData", "loading"]),
    },

    beforeDestroy() {
        for (var i of this.intervals) {
            clearInterval(i);
        }
    },

    methods: {
        ...mapActions("Auctions", [
            "setAuctions",
            "setAuctionTypes",
            "setRegions",
            "setCities",
            "resetAuctions",
            "resetInfo",
            "addToFav",
        ]),

        shareCard() {
            this.$refs.shareModal.open();
        },
        loveCard(id) {
            if (!this.auth_user) {
                this.$refs.loginModal.open();
            } else {
                let data = {
                    model_type: "Auction",
                    model_id: id,
                };
                this.addToFav(data);
            }
        },
        shareTwitter() {
            window
                .open(
                    "http://twitter.com/share?text=text&url=https://google.com&hashtags=hashtag1,hashtag2,hashtag3",
                    "_blank"
                )
                .focus();
        },
        shareWhatsapp() {
            window.open("https://wa.me/?text=urlencodedtext", "_blank").focus();
        },
        handleCount(item) {
            var itemId = item.created_at + "-" + item.id;
            if (item.statusByDate != 3) {
                var start_at = item.startTime;
                // Set the date we're counting down to
                if (start_at && itemId) {
                    var countDownDate = new Date(
                        Date.parse(start_at.replace(/-/g, "/"))
                    ).getTime();

                    // Update the count down every 1 second
                    if (!this.addedIntervals.includes(itemId)) {
                        var x = setInterval(() => {
                            // Get today's date and time
                            var now = new Date().getTime();

                            // Find the distance between now and the count down date
                            var distance = countDownDate - now;

                            // Time calculations for days, hours, minutes and seconds

                            let days_value = Math.floor(distance / (1000 * 60 * 60 * 24));
                            let hours_value = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                            let minutes_value = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                            let seconds_value = Math.floor((distance % (1000 * 60)) / 1000);

                            if (days_value < 10)
                                var days = '0' + days_value;
                            else var days = days_value;

                            if (hours_value < 10)
                                var hours = '0' + hours_value;
                            else var hours = hours_value;

                            if (minutes_value < 10)
                                var minutes = '0' + minutes_value;
                            else var minutes = minutes_value;

                            if (seconds_value < 10)
                                var seconds = '0' + seconds_value;
                            else var seconds = seconds_value;

                            if (distance > 0) {
                                if (document.getElementById(`${itemId}-seconds`) != null)

                                    document.getElementById(`${itemId}-seconds`).innerHTML =
                                    seconds;
                                if (document.getElementById(`${itemId}-minutes`) != null)
                                    document.getElementById(`${itemId}-minutes`).innerHTML =
                                    minutes;
                                if (document.getElementById(`${itemId}-hours`) != null)
                                    document.getElementById(`${itemId}-hours`).innerHTML = hours;
                                if (document.getElementById(`${itemId}-days`) != null)
                                    document.getElementById(`${itemId}-days`).innerHTML = days;
                            } else {
                                if (document.getElementById(`${itemId}-seconds`) != null)
                                    document.getElementById(`${itemId}-seconds`).innerHTML = 0;
                                if (document.getElementById(`${itemId}-minutes`) != null)
                                    document.getElementById(`${itemId}-minutes`).innerHTML = 0;
                                if (document.getElementById(`${itemId}-hours`) != null)
                                    document.getElementById(`${itemId}-hours`).innerHTML = 0;
                                if (document.getElementById(`${itemId}-days`) != null)
                                    document.getElementById(`${itemId}-days`).innerHTML = 0;
                            }

                            // If the count down is finished, write some text
                            if (distance < 0) {
                                clearInterval(x);
                                (this.intervals = []),
                                (this.addedIntervals = []),
                                this.setAuctions();

                                //   document.getElementById("demo").innerHTML = "EXPIRED";
                            }
                        }, 1000);

                        this.intervals.push(x);
                        this.addedIntervals.push(itemId);
                    }
                }
            }
        },

        reloadData() {},

        getDateTime(date) {
            let preDateTime = new Date(date);
            let newTime = preDateTime.toLocaleTimeString("en-US");
            let hour = newTime.split(":")[0];
            let amPm = newTime.split(" ")[1];
            let seconds = newTime.split(":")[2].replace(amPm, "");

            let noSeconds = newTime.replace(":" + seconds, " ");

            if (parseInt(hour) < 9) {
                noSeconds = "0" + noSeconds;
            }

            return noSeconds;
        },

        getDiffInDays(s_date, e_date) {
            var st_date = new Date(s_date);
            var ed_date = new Date(e_date);
            var difference = ed_date.getTime() - st_date.getTime();

            var seconds = Math.floor(difference / 1000);
            var minutes = Math.floor(seconds / 60);
            var hours = Math.floor(minutes / 60);
            var days = Math.floor(hours / 24);

            return days;
        },

        formatOptions(list) {
            var options = [];
            for (var item of list) {
                options.push({
                    value: item.id,
                    label: item.name,
                });
            }
            return options;
        },

        getStatus(status) {
            switch (status) {
                case 1:
                    return "قادم";
                    break;
                case 2:
                    return "جاري";
                    break;
                case 3:
                    return "منتهي";
                    break;
            }
        },

        async excuteClearInterval() {
            for (var i of this.intervals) {
                clearInterval(i);
            }
        },

        startTimer() {
            for (let index = 0; index < this.lists.auctions.length; index++) {
                this.handleCount(this.lists.auctions[index], index);
            }
        },

        async selectRegion(val) {
            this.info.region_id = val;
            this.setAuctions();
            this.setCities(val);
        },

        async selectCity(val) {
            this.info.city_id = val;
            this.setAuctions();
        },

        getRoute(id) {
            return "/auctions/" + id;
        },

        getProfileRoute(id) {
            window.open("/company_profile/" + id, "_blank");
        },

        async filterByCat(id) {
            console.log(id);
            this.aciveFilterCat = id;
            await this.resetAuctions();
            this.info.type_id = id;
            this.setAuctions().then(() => {
                this.startTimer();
            });
        },

        async filterByStuts(status) {
            this.activeFilterStatus = status;
            await this.resetAuctions();
            this.info.status = status;
            this.setAuctions().then(() => {
                this.startTimer();
            });
        },

        async showAll() {
            this.info.status = null;
            this.activeFilterStatus = 0;
            await this.resetAuctions();
            this.setAuctions();
        },

        setData() {
            this.info.type_id = 1;
            this.setAuctions();
        },

        formatDate(date) {
            var d1 = new Date(date);
            var ms = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
            return d1.getDate() + "-" + ms[d1.getMonth()] + "-" + d1.getFullYear();
        },
    },

    async mounted() {
        this.setData();
        this.setAuctionTypes();
        this.setRegions();
        // this.handleCount();
    },
};
</script>

<style lang="scss" scoped>
.cards-component {
    background-color: #f8fafc;
}

.noauctions {
    img {
        width: 70%;
        height: 100%;
    }
}

.form-input {
    width: 100%;
}

.types {
    display: flex;

    .type {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #838487;
        background-color: #e9e9e9;
        border-left: 1px solid #838487;
        border-bottom: 1px solid #838487;
        padding: 10px;
        cursor: pointer;

        &:hover {}

        img {
            width: 70px;
            padding: 5px;
        }

        .text {
            font-size: 25px;
        }

        .sofa {
            img {
                width: 50px;
            }
        }
    }
}

.active {
    background: $primary;
    color: #f8fafc;

    .text {
        font-weight: bold;
        color: white;
    }
}

a {
    &:hover {
        text-decoration: none;
    }
}

.search-bar {
    margin-top: 30px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right {
        display: flex;
        align-items: center;

        .input-con {
            display: flex;
            align-items: center;
            margin-left: 10px;

            .form-input {
                border: none;
                outline: none;
                max-width: 200px;
                width: 200px;
                height: 100%;
                border-radius: 0 5px 5px 0;
            }

            .img-con {
                background-color: $primary;
                padding: 8px;
                border-radius: 5px 0px 0px 5px;
                margin-top: 9px;

                img {
                    width: 20px;
                }
            }
        }

        .bell-con {
            background-color: #e9e9e9;
            padding: 8px;
            border-radius: 5px;
            height: 50px;
            margin-top: 15px;

            img {
                width: 20px;
            }
        }
    }

    .left {
        color: $primary;

        ul {
            list-style: none;
            padding: 5px 10px;
            display: flex;
            box-shadow: 4px 6px 5px 0px rgba(181, 181, 181, 1);
            border-radius: 20px;

            li {
                margin-left: 6px;
                padding: 2px 10px;
                font-weight: bold;
            }

            a {
                color: $primary;
            }

            li.active {
                background: $secondary;
                color: white;
                padding: 2px 20px;
                border-radius: 20px;

                a {
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }
            }
        }
    }
}

.card-con {
    padding: 10px;
}

.card {
    margin-bottom: 20px;
    position: relative;
    box-shadow: 4px 6px 5px 0px #f8fafc;

    .finished,
    .stopped {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #838487;
        z-index: 97;
        opacity: 0.4;
        border-radius:11px;
    }

    .finished-text,
    .stopped-text {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 98;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 40px;
        color: white;

        .text-en {
            color: #3b3b3b;
        }
    }

    .card-top-con {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        margin-top: -20px;
    }

    .card-top-over-con {
        position: absolute;
        top: 0;
        right: 0;
        width: 90%;
        // margin-top: -20px;
        margin: -20px auto 0px auto;
        height: 20px;
        background-color: #838487;
        z-index: 97;
        opacity: 0.4;
        margin-right: 5%;
        border-radius: 9px 9px 0px 0px;
    }

    .card-top {
        width: 90%;
        margin: auto;
        padding: 10px;
        border-radius: 10px;

        .left {
            display: flex;

            img {
                margin-left: 6px;
                width: 20px;
                height: 20px;
            }
        }
    }

    .card-heading {
        color: white;
        background: #484080;
        width: 90%;
        margin: 0 auto;
        padding: 10px;
        border-radius: 10px;
        position: absolute;
        top: -20px;
        right: 21px;
        z-index: 2;
    }

    .card-label {
        position: absolute;
        top: 30px;
        right: 0px;
        background-color: white;
        z-index: 2;
        padding: 4px 12px;
        border-radius: 10px 0 0 10px;
    }

    .card-img {
        height: 220px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 10px 10px 0 0;
        }

        .time-left {
            position: absolute;
            bottom: 0;
            left: 30px;
            padding: 5px 30px 0px;
            border-radius: 5px 5px 0 0;
            font-size: 16px;
            font-weight: bold;
            color: white;
        }
    }

    .card-text {
        .card-text-heading {
            padding: 20px 10px 10px 10px;

            color: white;
            margin-top: -12px;

            .row {
                align-items: center;
            }

            .name-status {
                display: flex;

                .heading-title {
                    font-weight: bold;
                }

                .heading-location {
                    display: flex;

                    img {
                        width: 10px;
                        margin-left: 5px;
                    }
                }

                .status {
                    margin-right: 10px;
                }
            }
        }
    }
}

.mz_cards_side_time_left {
    color: white;

    //padding: 10px 15px;
    padding-top: 0px;

    //margin-top: 10px;
    .row {
        width: 100%;
        align-items: center;
        margin: 0;
    }

    .mz_time_left_items {
        display: flex;
        width: 100%;
        justify-content: space-evenly;

        .time_dots {
            font-size: 30px;
            font-weight: bold;
            margin-top: -6px;
        }

        .mz_time_left_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            font-size: 12px;
            width: 100%;

            .mz_time_left_item_value {
                color: white;
                font-size: 26px;
                font-weight: bold;

                &::after {
                    //   content: ":";
                    //   margin-right: 20px;
                }
            }

            .day_value {
                &::after {
                    content: "" !important;
                    margin-right: 0px !important;
                }
            }

            .mz_time_left_item_dots {
                display: none;
                color: white;
                position: absolute;
                top: -6px;
                right: 31px;
                font-size: 25px;
            }
        }
    }
}

.card-body {
    background-color: #e9e9e9;
    color: #838487;
    font-size: 15px;

    .icon {
        padding-left: 5px;
    }

    .b-b {
        border-bottom: 1px solid white;
    }

    .card-body-item {
        padding: 10px;
        display: flex;

        span {
            color: $primary;
        }

        .icon {
            img {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.card-footer {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    border-block-color: white;
    background-color: #e9e9e9;
    border-radius: 0 0 10px 10px;

    .right {
        margin-top: 25px;

        img {
            width: 20px;
            height: 20px;
            margin-left: 8px;
        }
    }

    .left {
        .logo {
            width: 120px;
            margin-left: 10px;
        }
    }

    .card-footer-imgs {
        color: #838487;
        display: flex;
        justify-content: space-between;
        width: 100%;

        img {
            width: 60px;
        }
    }

    .right {
        display: flex;

        img {
            width: 20px;
            height: 20px;
            margin-left: 8px;
            cursor: pointer;
        }
    }

    .left {
        .logo {
            width: 120px;
            margin-left: 10px;
        }

        .arrow {
            width: 25px;
        }
    }
}

.active-filter {
    background: $secondary !important;
}

.social-btn {
    svg {
        fill: $primary;
    }

    &:hover {
        svg {
            fill: $secondary;
        }
    }
}
</style>
