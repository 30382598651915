<template>
<section class="header">
    <div class="mobile-navbar" :class="{ 'show-nav': showNav }">
        <div class="mobile-navbar-header">
            <button @click="showNav = false">
                <unicon name="times-circle" fill="#fff" width="30" height="30"></unicon>
            </button>
        </div>
        <div class="links">
            <ul>
                <li @click="showNav = false">
                    <router-link to="/login" v-if="!auth_user">
                        <button class="login-btn">تسجيل الدخول</button>
                    </router-link>
                    <button v-else class="user-btn">
                        <span v-if="auth_user.type == 1"> {{ auth_user.f_name }} </span>
                        <span v-else>
                            {{ auth_user.company_name }}
                        </span>
                    </button>
                </li>

                <li @click="showNav = false" v-if="auth_user">
                    <router-link :to="getRoute()">
                        <span class="drop-down-text"> منطقة العميل </span>
                    </router-link>
                </li>
                <li v-if="auth_user && auth_user.type == 3" @click="showNav = false">
                    <router-link :to="getProfileRoute()">
                        <span class="drop-down-text"> صفحتي </span>
                    </router-link>
                </li>
                <li @click="showNav = false">
                    <router-link to="/"> الرئيسية </router-link>
                </li>
                <li @click="showNav = false">
                    <router-link to="/auctions"> المزادات </router-link>
                </li>
                <li @click="showNav = false">
                    <router-link to="/companies">الشركات </router-link>
                </li>
                <li @click="showNav = false">
                    <router-link to="/call-us"> اتصل بنا </router-link>
                </li>
                <li @click="logout()" class="logout-li" v-if="auth_user">
                    <a>
                        <span class="drop-down-text"> تسجيل الخروج </span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="container">
        <div class="header-con">
            <div class="right-side">
                <div class="logo">
                    <router-link to="/">
                        <img src="@/assets/logo/logo.png" width="100" height="100" alt="" />
                    </router-link>
                </div>
                <div class="links">
                    <ul>
                        <li>
                            <router-link to="/"> الرئيسية </router-link>
                        </li>
                        <li>
                            <router-link to="/auctions"> المزادات </router-link>
                        </li>

                        <li>
                            <router-link to="/companies">الشركات </router-link>
                        </li>

                        <li>
                            <router-link to="/contact_us"> تواصل معنا </router-link>
                        </li>

                        <!-- <li>
                <router-link to="/call-us"> اتصل بنا </router-link>
              </li> -->
                    </ul>
                </div>
            </div>
            <div class="left-side">
                <router-link to="/login" v-if="!auth_user">
                    <button class="login-btn">تسجيل الدخول</button>
                </router-link>

                <button v-else class="user-btn">
                    <span v-if="auth_user.type == 1"> {{ auth_user.f_name }} </span>
                    <span v-else>
                        {{ auth_user.company_name.substring(0, 10) + "..." }}
                    </span>
                </button>
                <div class="header-drop-down" v-if="auth_user">
                    <ul>
                        <li>
                            <router-link :to="getRoute()">
                                <unicon name="create-dashboard" fill="#000000" width="15" height="15"></unicon>
                                <span class="drop-down-text"> منطقة العميل </span>
                            </router-link>
                        </li>
                        <li v-if="auth_user && auth_user.type == 3">
                            <router-link :to="getProfileRoute()">
                                <unicon name="user" fill="#000000" width="15" height="15"></unicon>
                                <span class="drop-down-text"> صفحتي </span>
                            </router-link>
                        </li>
                        <li @click="logout()" class="logout-li">
                            <a>
                                <unicon name="signout" fill="#000000" width="15" height="15"></unicon>
                                <span class="drop-down-text"> تسجيل الخروج </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="mobile-bars">
                <button @click="showNav = true">
                    <unicon name="bars" fill="#484080" width="32" height="32" class="bars-icon" />
                </button>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ModalExample from "@/components/ModalExample";
import NafathModal from "@/components/Modals/NafathModal";

export default {
    computed: {
        ...mapGetters("Auth", ["auth_user"]),
    },
    data() {
        return {
            showDrop: false,
            showNav: false,
        };
    },

    methods: {
        showExampleModal() {
            // Documentation: https://euvl.github.io/vue-js-modal/Installation.html#client
            this.$modal.show(
                NafathModal, { name: "nafathModal", text: "This text is passed as a property" }, { height: "auto", scrollable: true }
            );
        },
        ...mapActions("Auth", ["logout"]),
        getProfileRoute() {
            return "/company_profile/" + this.auth_user.id;
        },
        getRoute() {
            if (this.auth_user) {
                if (this.auth_user.type == 1) {
                    return "/client/client-profile";
                } else {
                    return "/client/company-profile";
                }
            } else {
                return "/client/company-profile";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    position: sticky;
    top: 0px;
    background: white;
    z-index: 99;

    .header-con {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right-side {
            display: flex;
            align-items: center;

            .logo {
                img {
                    width: 160px;
                }
            }

            .links {
                ul {
                    display: flex;
                    list-style-type: none;
                    padding: 0;
                    margin-bottom: 0;
                    margin-right: 20px;

                    li {
                        margin-right: 10px;

                        a {
                            color: $primary;
                            font-weight: bold;
                            font-size: 18px;
                        }

                        a.router-link-exact-active {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .left-side {
            &:hover {
                .header-drop-down {
                    display: block !important;
                }
            }

            position: relative;

            button {
                background-color: #f3f4f1;
                color: black;
                padding: 6px 20px;
                border-radius: 50px;
                border: none;
                cursor: pointer;
                outline: none;
                width: 170px;
                overflow: hidden;
                text-align: center;
            }

            .login-btn {
                width: 100%;
                max-width: 640px;
                cursor: pointer;
                background-color: $primary;
                color: white;
                padding: 10px;
                border: none;
                border-radius: 6px;
                text-align: center;
                margin-top: 10px;
            }

            .header-drop-down {
                display: none;
                background: white;
                position: absolute;
                top: 35px;
                left: 0px;
                border: 1px solid #ccc;
                width: 100%;
                border-radius: 4px;

                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;

                    li.logout-li {
                        cursor: pointer;
                    }

                    li {
                        padding: 10px 15px;

                        border-bottom: 1px solid #ddd;
                    }

                    a {
                        color: black;
                        font-size: 15px;
                    }
                }
            }

            &:hover {
                display: block !important;
            }
        }
    }
}

.mobile-bars {
    display: none;

    button {
        background: transparent;
        border: none;
    }
}

.show-nav {
    left: 0 !important;
}

.mobile-navbar {
    position: absolute;
    top: 0;
    left: -100%;
    height: 100vh;
    width: 100%;
    background-color: $primary;
    z-index: 999;
    transition: all 1s;

    button {
        background: transparent;
        border: none;
    }

    .mobile-navbar-header {
        display: flex;
        justify-content: flex-end;
        padding: 10px;
    }

    .links {

        // display: flex;
        // align-items: center;
        // justify-content: center;
        ul {
            list-style-type: none;

            li {
                color: white;

                button {
                    color: white;
                }

                span {
                    color: white;
                }

                a {
                    color: white;
                    font-size: 20px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
</style>
