// import Vue from 'vue';
import { nafathAxios } from "../../main";

const state = {
   loading: false,
   loginError: undefined,
   random_number :undefined
  
};

const getters = {
   loading: (state) => state.loading, 
   loginError:  (state) => state.loginError,
   random_number:(state) => state.random_number
  
};

const actions = {
  async sendNafathReq({ commit, dispatch, state }, id_number) {
    commit("setLoading", true);

    return new Promise((resolve, reject) => {
      let data = {
        id_number : id_number
      }
      nafathAxios
        .post("send_request", data)
        .then((response) => {
          console.log(response.data)
          commit("setLoading", false);
          if (response.data.code) {
            resolve(response)
            dispatch(
              "Alert/successMsg",
              {
                msg: "",
              },
              {
                root: true,
              }
            );

           
            
          } else {
            state.loginError = response.data.message;
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          state.loginError = true;
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  setRandomNumber({commit} , random_number){
    commit("setRandomNumber" ,  random_number)
  },

  
  setLoading({ commit }, loading) {
    commit("setLoading", loading);
  },
};
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },

  setRandomNumber(state, random_number) {
    state.random_number = random_number;
  },
 

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
