<template>
  <div class="modal-con">
    <FormulateForm
    class="modal-form"
    v-model="formValues"
  >
    <h2 class="form-title">اضافة حساب بنكي</h2>
    <p class="form-description">
      اضافة حساب بنكي اضافة حساب بنكي اضافة حساب بنكي اضافة حساب بنكي اضافة حساب بنكي اضافة حساب بنكي اضافة حساب بنكي اضافة حساب بنكي اضافة حساب بنكي
    </p>
    <FormulateInput
      name="name"
      type="text"
      label="اسم المستخدم"
      help="يجب ان يكون اسم المستخدم مطابق لاسم الحساب المسجل"
      validation="required"
    />
    <FormulateInput
      name="acount_no"
      type="text"
      label="رقم الحساب"
      
      validation="required|number"
    />
    <FormulateInput
      name="iban_no"
      type="text"
      label="رقم الايبان"
      placeholder = "xxxxxxxxxxxxxxxx"
      validation="required"
      @blur="getBankName"
    />
    <FormulateInput
      name="bank_name"
      type="text"
      disabled
      label="اسم البنك "
      
      validation="required|email"
    />
    <FormulateInput
      name="name"
      type="text"
      label="الاسم"
      validation="required"
    />
   
    
    <FormulateInput
      type="submit"
      label="المتابعة"
    />
  </FormulateForm>
  </div>
</template>

<script>
export default {
  data () {
    return {
      formValues: {
        iban_no : SA
      }
    }
  },

  methods : {
    getBankName(){
        
    }
  }
}
</script>

<style scoped lang="scss">
.modal-con {
  direction: rtl;
}
.modal-form {
  padding: 2em;
  border-radius: .5em;
  box-sizing: border-box;
}
.form-title {
  margin-top: 0;
  font-weight: bold;
  font-size: 22px;
}
.form-description {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 14px;
}

.modal-form::v-deep .formulate-input .formulate-input-element {
  max-width: none;
}
</style>