import { render, staticRenderFns } from "./ModalExample.vue?vue&type=template&id=873cbdc6&scoped=true"
import script from "./ModalExample.vue?vue&type=script&lang=js"
export * from "./ModalExample.vue?vue&type=script&lang=js"
import style0 from "./ModalExample.vue?vue&type=style&index=0&id=873cbdc6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "873cbdc6",
  null
  
)

export default component.exports