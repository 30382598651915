// import Vue from 'vue';
import { clientAreaAxios } from "../main";


const state = {
  loading: false,
  walletData: {},
  invoiceNumber : undefined ,
  formData: {},
  banks_code : [
    {
      code: '10',
      name: 'البنك الاهلي'
    },
    {
        code: '80',
        name: 'مصرف الراجحي'
    },
    {
        code: '15',
        name: 'بنك البلاد'
    },
    {
        code: '20',
        name: 'بنك الرياض'
    },
    {
        code: '05',
        name: 'بنك الانماء'
    },
    {
        code: '60',
        name: 'بنك الجزيرة'
    },
    {
        code: '30',
        name: 'البنك العربي'
    },
    {
        code: '40',
        name: 'سامبا'
    },
    {
        code: '45',
        name: 'ساب'
    },
    {
        code: '50',
        name: 'السعودي الهولندي'
    },
    {
      code: '55',
      name: 'السعودي الفرنسي'
    },
    {
      code: '95',
      name: 'بنك الامارات'
    },
   
  ],
  lists: {
    componies: {},
    auctions: [],
    accounts : [] 
  },

  info: {}
};

const getters = {
  loading:        (state) => state.loading,
  lists:          (state) => state.lists,
  formData:       (state) => state.formData,
  info:           (state) => state.info,
  walletData:     (state) => state.walletData ,
  invoiceNumber : (state) => state.invoiceNumber 

};

const actions = {
  async setWalletData({ commit, dispatch }) {
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .get("get_wallet_data")
        .then((response) => {
          commit("setLoading", false);
          commit("setWalletData", response.data);

          resolve(response)
        })
        .catch((error) => {
          commit("setLoading", false);

          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  addWithdrowRequest({ commit, dispatch }) {

  },


  removeWithdrowRequest({ commit, dispatch }) {

  },

  editWithdrowRequest({ commit, dispatch }) {

  },

  setDepositePrice({commit} , price){
    commit('setDepositePrice' , price)
  },

  editAccount({ commit, dispatch }, formData){
    commit("setLoading", true);

    return new Promise((resolve, reject) => {
      clientAreaAxios
        .patch("update_account/" + formData.id, formData)
        .then((response) => {
          commit("setLoading", false);

          if (response.data.status) {
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: "",
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  addAccount({ commit, dispatch }, formData){
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .post("add_account", formData)
        .then((response) => {
          commit("setLoading", false);

          if (response.data.status) {
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: "",
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  deavtivate({ commit, dispatch }, account_id){
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .post("account_activation/" + account_id)
        .then((response) => {
          commit("setLoading", false);

          if (response.data.status) {
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: "",
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  setUserAccount({ commit }, user_id){
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .get("get_user_accounts/" + user_id)
        .then((response) => {
          commit("setLoading", false);
          commit("setUserAccount", response.data);
        
          resolve(response);
        })
        .catch((err) => {
          reject(err);
          commit("setLoading", false);
          console.log(err.response);
        });
    });
  },

  addAccount({ commit, dispatch } , formData){
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .post("add_account", formData)
        .then((response) => {

          commit("setLoading", false);
          if (response.data.status) {
            resolve(response)
          }

        })
        .catch((error) => {
          commit("setLoading", false);

          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

 

  generateInvoice({ commit, dispatch, state }) {
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .post("generate_invoice", state.formData)
        .then((response) => {

          commit("setLoading", false);
          if (response.data.status) {
            commit("setInvoiceNumber" , response.data.invoiceNumber)
            resolve(response)
          }

        })
        .catch((error) => {
          commit("setLoading", false);

          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },









  setLoading({ commit }, loading) {
    commit("setLoading", loading);
  },
};
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },

  setInvoiceNumber(state , num){
    state.invoiceNumber = num
  },

  setDepositePrice(state , price){
    state.formData.totalAmount = price
  },

  setUserAccount(state , data){
    state.lists.accounts = data
  },


  setWalletData(state, payload) {
    state.walletData = payload;

  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
