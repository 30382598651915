<template>
  <section class="nav-bar">
    <div class="container">
      <div class="nav-con">
        <div class="nav-items">
          <ul>
            <li>
              <router-link to="/">عنا</router-link>
            </li>
            <li>
              <router-link to="/">تواصل معنا</router-link>
            </li>
            <li>
              <router-link to="/companies">الشركات</router-link>
            </li>
            <li>
              <router-link to="/client_area">منطقة العميل</router-link>
            </li>
            <li>
              <router-link to="/privacy">سياسة الخصوصية</router-link>
            </li>
            <li>
              <router-link to="/terms">شروط الإستخدام</router-link>
            </li>
            <li class="lang">
              <router-link to="/">English</router-link>
            </li>
          </ul>
        </div>
        <div class="nav-btn">
          <button @click="goToRegister()">
            <span class="icon">
              <unicon name="signin" fill="#ffffff" width="20"></unicon>
            </span>
            <span class="text"> تسجيل حساب </span>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    goToRegister() {
      this.$router.push("/register");
    },
  },
};
</script>

<style scoped lang="scss">
.nav-bar {
  background-color: #e9e9e9;
  .nav-con {
    display: flex;
    justify-content: space-between;
    .nav-items {
      ul {
        list-style-type: none;
        display: flex;
        height: 100%;
        li {
          display: flex;
          align-items: center;
          margin-left: 8px;
          transition: 0.3s;

          a {
            padding: 5px;
            color: black;
            text-decoration: none;
          }
          &:hover {
            background: white;
          }
        }
        .lang {
          background: $primary;

          a {
            color: white;
          }
          &:hover {
            background: $primary;
          }
        }
      }
    }
  }
  .nav-btn {
    button {
      display: flex;
      align-items: center;
      background-color: $primary;
      border: none;
      color: white;
      padding: 0px 8px;
      height: 100%;
      cursor: pointer;
      .icon {
        width: 20px;
      }
      .text {
        margin-right: 6px;
      }
    }
  }
}
</style>
