import Vue from "vue";
import Vuex from "vuex";

import Auctions from "./auctions/index";
import Auth from "./auth/index";
import Nafath from "./auth/nafath";
import Clientarea from "./auctions/clientarea";
import Alert from "./alert";
import Companies from "./companies";
import Wallet from "./wallet";
Vue.use(Vuex);


const store = new Vuex.Store({
  // plugins: [vuexLocal.plugin],

  state: {
    activeCon: "carousel",
    cloudinaryCloudName: "dygyznfnf",
    cloudinaryUploadPreset: "ssk9jn3o"
  },
  getters: {
    activeCon: (state) => state.activeCon,
    cloudinaryCloudName: (state) => state.cloudinaryCloudName,
    cloudinaryUploadPreset: (state) => state.cloudinaryUploadPreset,
  },
  mutations: {
    setActiveCon(state, payload) {
      state.activeCon = payload.newActive;
    },
  },

  modules: {
    Auctions,
    Clientarea,
    Auth,
    Alert,
    Companies,
    Wallet ,
    Nafath
  },
});

export default store
